/* eslint-disable react/prop-types */
import React from "react"
import Img from "gatsby-image"
import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import Container from "../components/Container"
import { H1, H2 } from "../components/StyledHeaders"
import StandardSection from "../components/StandardSection"
import { Box } from "@rebass/grid/dist/emotion"

const NonStretchedImage = props => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        width: "90%",
        maxWidth: props.fluid.presentationWidth,
        margin: "auto", // Used to center the image
      },
    }
  }

  return <Img {...normalizedProps} />
}

export const fluidImage = graphql`
  fragment carouselImage on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
        presentationWidth
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    cf: file(relativePath: { eq: "nuevo-centralfresh-mx.png" }) {
      ...carouselImage
    }
    ascg: file(relativePath: { eq: "ascg-mx.png" }) {
      ...carouselImage
    }
    asnews: file(relativePath: { eq: "asnews-mx.png" }) {
      ...carouselImage
    }
    asxpert: file(relativePath: { eq: "asxpert-mx.png" }) {
      ...carouselImage
    }
    benefit: file(relativePath: { eq: "benefitadministracion.png" }) {
      ...carouselImage
    }
  }
`

const Portfolio = () => {
  const data = useStaticQuery(pageQuery)

  console.log(data)

  var settings = {
    className: "",
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  }
  return (
    <Layout>
      <SEO title="Portfolio" />

      <Hero height={["40vh", "45vh"]}>
        <Container>
          <div style={{ maxWidth: 720 }}>
            <H1>Our work</H1>
          </div>
        </Container>
      </Hero>

      <StandardSection>
        <Box id="services" pt={["60px", "80px"]}>
          <Container>
            <H2>Recent work</H2>

            <Slider {...settings}>
              <div>
                <NonStretchedImage {...data.cf.childImageSharp} />
              </div>
              <div>
                <NonStretchedImage {...data.ascg.childImageSharp} />
              </div>
              <div>
                <NonStretchedImage {...data.asnews.childImageSharp} />
              </div>
              <div>
                <NonStretchedImage {...data.asxpert.childImageSharp} />
              </div>
              <div>
                <NonStretchedImage {...data.benefit.childImageSharp} />
              </div>
            </Slider>
          </Container>
        </Box>
      </StandardSection>
    </Layout>
  )
}

export default Portfolio
